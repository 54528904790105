import { ChainId, Token } from '@mo/mowap-sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

export const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
  wmo: new Token(
    MAINNET,
    '0x9B5eFe597851Cb8647B5b591405DcEA4a3163761',
    18,
    'WMO',
    'Wrapped MO',
    'https://mainnet.mochain.app/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  mo: new Token(MAINNET, '0x9B5eFe597851Cb8647B5b591405DcEA4a3163761', 18, 'MO', 'MO', 'https://mainnet.mochain.app/'),
  mojo: new Token(
    MAINNET,
    '0xf56dbfD7145b55950cB973AE5d837f8D1Eb4ae5c',
    18,
    'MOJO',
    'MOJO',
    'https://mainnet.mochain.app/',
  ),
  musdt: new Token(MAINNET, '0x78677b0a995Bd4a6C69FB289E2a2C3601a8E34f6', 18, 'MUSDT', 'MUSDT', ''),
  musdc: new Token(MAINNET, '0x700905Db7e2E16a33eAe9Bd537aa56EB2605674C', 18, 'MUSDC', 'MUSDC', ''),
  syrup: new Token(MAINNET, '0xca9A311C4ff271d50a5780a785406c06A630e5B7', 18, 'SYRUP', 'SYRUP', ''),
} as const)

export const testnetTokens = defineTokens({
  wmo: new Token(
    TESTNET,
    '0x9B5eFe597851Cb8647B5b591405DcEA4a3163761',
    18,
    'WMO',
    'Wrapped MO',
    'https://mainnet.mochain.app/',
  ),
  mojo: new Token(
    TESTNET,
    '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
    18,
    'MOJO',
    'MOJO Token',
    ' https://mojito.to/',
  ),
  busd: new Token(
    TESTNET,
    '0x8B92eD4F4A663F75561c452caf4fdA801eFefCCd',
    18,
    'BUSD',
    'MOJO Smart USD',
    '',
  ),
  syrup: new Token(
    TESTNET,
    '0x12f369B5a821f56924970262670b1aA10b0D62fF',
    18,
    'SYRUP',
    'SyrupBar Token',
    ' https://mojito.to/',
  ),
  // bake: new Token(
  //   TESTNET,
  //   '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   18,
  //   'BAKE',
  //   'Bakeryswap Token',
  //   'https://www.bakeryswap.org/',
  // ),
} as const)

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
