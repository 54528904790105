function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
import { light as lightAlert } from "@mo/uikit/src/components/Alert/theme";
import { light as lightCard } from "@mo/uikit/src/components/Card/theme";
import { light as lightPancakeToggle } from "@mo/uikit/src/components/PancakeToggle/theme";
import { light as lightRadio } from "@mo/uikit/src/components/Radio/theme";
import { light as lightToggle } from "@mo/uikit/src/components/Toggle/theme";
import { light as lightTooltip } from "@mo/uikit/src/components/Tooltip/theme";
import { light as lightNav } from "@mo/uikit/src/widgets/Menu/theme";
import { light as lightModal } from "@mo/uikit/src/widgets/Modal/theme";
import base from "@mo/uikit/src/theme/base";
import { lightColors } from "@mo/uikit/src/theme/colors";
var lightTheme = _objectSpread({}, base, {
    isDark: false,
    alert: lightAlert,
    colors: lightColors,
    card: lightCard,
    toggle: lightToggle,
    nav: lightNav,
    modal: lightModal,
    pancakeToggle: lightPancakeToggle,
    radio: lightRadio,
    tooltip: lightTooltip
});
export default lightTheme;
