import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'
import { ChainId } from '@mo/mowap-sdk'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'MOJO',
    lpAddresses: {
      [ChainId.TESTNET]: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      [ChainId.MAINNET]: '0xf56dbfD7145b55950cB973AE5d837f8D1Eb4ae5c',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wmo,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'MO-MOJO LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      [ChainId.MAINNET]: '0xaE1Fb87FcF1662672AFcE22C6865172aAc681b82',
    },
    token: serializedTokens.mojo,
    quoteToken: serializedTokens.wmo,
  },
  {
    pid: 3,
    // v1pid: 2,
    lpSymbol: 'MUSDT-MO LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0xc8EdeC730CC6aBb57c213c840633071164357357',
      [ChainId.MAINNET]: '0x2e9E3984B96c405193eECd3C561bf9354D44ed18',
    },
    token: serializedTokens.wmo,
    quoteToken: serializedTokens.musdt,
  },
  {
    pid: 4,
    // v1pid: 3,
    lpSymbol: 'MUSDC-MO LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      [ChainId.MAINNET]: '0x9543bc48D3f8bA79006EE97fA44C051B9D21c5f2',
    },
    token: serializedTokens.wmo,
    quoteToken: serializedTokens.musdc,
  },
  {
    pid: 5,
    // v1pid: 3,
    lpSymbol: 'MUSDT-MOJO LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
      [ChainId.MAINNET]: '0x1551fe83F8073f8FA590245082E5A8A4df5ae5B3',
    },
    token: serializedTokens.mojo,
    quoteToken: serializedTokens.musdt,
  },
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
