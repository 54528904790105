function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  font-size: ",
        ";\n  font-weight: 600;\n  line-height: 1.1;\n  ",
        " {\n    font-size: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Text from "@mo/uikit/src/components/Text/Text";
import { tags, scales } from "@mo/uikit/src/components/Heading/types";
var _obj;
var style = (_obj = {}, _defineProperty(_obj, scales.MD, {
    fontSize: "20px",
    fontSizeLg: "20px"
}), _defineProperty(_obj, scales.LG, {
    fontSize: "24px",
    fontSizeLg: "24px"
}), _defineProperty(_obj, scales.XL, {
    fontSize: "32px",
    fontSizeLg: "40px"
}), _defineProperty(_obj, scales.XXL, {
    fontSize: "48px",
    fontSizeLg: "64px"
}), _obj);
var Heading = styled(Text).attrs({
    bold: true
}).withConfig({
    componentId: "sc-cb50bcb3-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSize;
}, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.lg;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSizeLg;
});
Heading.defaultProps = {
    as: tags.H2
};
export default Heading;
