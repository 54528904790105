function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
export var baseColors = {
    failure: "#ED931B",
    primary: "#ED931B",
    primaryBright: "#53DEE9",
    primaryDark: "#0098A1",
    secondary: "#7645D9",
    success: "#31D0AA",
    warning: "#ED931B"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _objectSpread({}, baseColors, additionalColors, {
    background: "#FAF9FA",
    backgroundDisabled: "#E9EAEB",
    backgroundAlt: "#FFFFFF",
    backgroundAlt2: "rgba(255, 255, 255, 0.7)",
    cardBorder: "#E7E3EB",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    input: "#eeeaf4",
    inputSecondary: "#d7caec",
    tertiary: "#EFF4F5",
    text: "#280D5F",
    textDisabled: "#BDC2C4",
    textSubtle: "#7A6EAA",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
export var darkColors = _objectSpread({}, baseColors, additionalColors, {
    secondary: "#ED931B",
    background: "#000000",
    backgroundDisabled: "#ECECEC",
    backgroundAlt: "#000000",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "#ffffff4d",
    contrast: "#FFFFFF",
    dropdown: "#151515",
    dropdownDeep: "#f2f2f2",
    invertedContrast: "#191326",
    input: "#f2f2f2",
    inputSecondary: "#717070",
    primaryDark: "#0098A1",
    tertiary: "#353547",
    text: "#000000",
    textDisabled: "#666171",
    textSubtle: "#000000",
    disabled: "#524B63",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)",
        cardHeader: "linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)",
        blue: "linear-gradient(180deg, #00707F 0%, #19778C 100%)",
        violet: "linear-gradient(90deg, #0C8D0C 0%, #0C8D0C 100%);",
        violetAlt: "linear-gradient(180deg, #434575 0%, #66578D 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)"
    }
});
